@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap);

  
 
  
  
body {
	background: #f3f6f9 !important;
	font-family: 'Roboto', sans-serif !important;
	/* font-family: 'Lato', sans-serif; */
}

.table thead th:focus {
	outline: unset !important;
}

a, a:hover, a:focus {
	color: inherit;
	text-decoration: none;
	transition: all 0.2s;
}

.fs-6 {
	font-size: 14px !important;
}

.fs-7 {
	font-size: 1.3rem !important;
}

.fs-24 {
	font-size: 24px !important;
}

.cp {
	cursor: pointer;
}

.mark-color {
	color: #f64e60 !important;
}

.check-color {
	color: #1bc5bd !important;
}

.ban-color {
	color: #f0a1a9 !important;
}

.deep-ban-color {
	color: #ff0019 !important;
}

.c-btn-primary {
	color: #fff !important;
	background-color: #1bc5bd !important;
	border-color: #1bc5bd !important;
	box-shadow: none !important;
}

.c-btn-danger {
	color: #fff !important;
	background-color: #f64e60 !important;
	border-color: #f64e60 !important;
	box-shadow: none !important;
}

.c-btn-info {
	color: #fff !important;
	background-color: #6993ff !important;
	border-color: #6993ff !important;
	box-shadow: none !important;
}

.c-btn-cyan {
	color: #1bc5bd !important;
	background-color: #f3f6f9 !important;
	border-color: #f3f6f9 !important;
	box-shadow: none !important;
}

.c-btn-secondary {
	color: #3f4254 !important;
	background-color: #e4e6ef !important;
	border-color: #e4e6ef !important;
}

.pb-10, .py-10 {
	padding-bottom: 5rem !important;
}

.pt-10, .py-10 {
	padding-top: 5rem!important;
}

.pad-top-2 {
	padding-top: 2.5rem !important;
}

/* Alert Css */

#__react-alert__ div div div {
	background-color: #1bc5bd !important;
	border-color: #1bc5bd !important;
	color: #fff !important;
	margin: 15px 30px !important;
}

#__react-alert__ div div div svg {
	stroke: #fff;
}

#__react-alert__ div div div span {
	text-transform: capitalize;
}

.App {
	display: -webkit-flex;
	display: flex;
	width: 100%;
	-webkit-align-items: stretch;
	        align-items: stretch;
	/* position: fixed; */
	height: 100vh;
	z-index: 1;
}

.content_bg {
	box-shadow: 0 0 30px 0 rgba(82, 63, 105, .05);
	border: 0;
	padding: 5px;
	background: #fff;
}

.content-wrap-pad {
	padding: 0 1.25rem 0 1.25rem;
}

.font-size-sm {
	font-size: .925rem !important;
}

.in-progress {
	color: #17a2b8!important
}

.success {
	color: #28a745!important
}

.warning {
	color: #ffc107!important;
}

.border-btm {
	border-bottom: 2px solid #17a2b8;
}

.content {
	margin-left: 17% !important;
	transition: .5s;
}

.content-collpased {
	margin-left: 7% !important;
	transition: .5s;
}

.sidenav-btn {}

.sidenav-btn-possition {
	position: fixed;
	right: 0;
	bottom: 40px;
	height: 36px;
	width: 36px;
	z-index: 1098;
	left: 14%;
	transition: .5s !important;
}

.sidenav-btn-possition-collapsed {
	position: fixed;
	right: 0;
	bottom: 40px;
	height: 36px;
	width: 36px;
	z-index: 1098;
	left: 4%;
	transition: .5s !important;
}

/* .content.collapsed{
	margin-left: 6.5% !important;
	transition: .5s;
} */

.text-hover-primary:hover {
	transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease;
	color: #1bc5bd !important;
}

.text-justified {
	text-align: justify;
}

/* pagination css */

.pagination-overfolow {
	width: 100%;
	display: block;
	overflow-x: auto;
}

/* Button CSS */

.custom-filter-btn {
	height: auto;
	padding: 7px 15px;
}

/* Margin CSS */

.mrg-top-64 {
	margin-top: 64px !important;
}

/* Exam Form Css */

.exam_heading_with_action {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-align-items: center;
	        align-items: center;
}

/* Nav CSS */

.custom_nav {
	/* background: linear-gradient( 168deg, #13b4ca, #18cabe); */
	/* height: 50px; */
	margin-left: 21%;
}

.custom_bar_icon, .custom_bar_icon:focus, .custom_bar_icon.btn-primary.focus, .custom_bar_icon.btn-primary:focus {
	border: 0 !important;
	background: inherit !important;
	padding: 0.25rem 0.75rem !important;
	font-size: 24px !important;
	line-height: 1 !important;
	box-shadow: unset !important;
	color: #181c32!important;
}

.nav_text {
	color: #181c32!important;
	font-size: 15px;
}

.transition-1 {
	transition: .5s !important;
}

/* Question Css */

.question_img_area img {
	width: 100%;
	max-width: 600px;
	border-radius: 5px;
}



/* DragDrop CSS */
.details-modal-question-bg{
	border: 1px solid #f1c0c5  !important;
    background: #f1c0c5  !important;
}
.draggable-item{
	border: 1px solid #f1c0c5  !important;
    background: #f1c0c5  !important;
}
.droppable-item{
	cursor: unset !important;
}
.draggable-item, .droppable-item{
	padding: 15px 40px;
}

.draggable-item.dragged-left{
width: 100%;
margin-left: -40px;
margin-bottom: -5px;
margin-top: 5px;
z-index: 999;
}
.draggable-item.dragged-right{
width: 100%;
margin-left: 40px;
margin-bottom: -5px;
margin-top: 5px;
z-index: 999;
}
.bg-gray {
	background-color: #d3d3d3 !important;
}

.bg-light-green {
	background-color: #dffcdd !important;
}






@media only screen and (max-width: 1270px) {
	.draggable-item, .droppable-item{
		padding: 5px;
	}
}


@media only screen and (max-width: 445px) {
	/* mobile margin bottom css */
	.mb-mrg-top-2 {
		margin-top: 1.25rem;
	}
	.mb-mrg-btm-2 {
		margin-bottom: 1.25rem;
	}
	.mb-mrg-top {
		margin-top: .25rem!important;
	}
	.mb-mrg-btm {
		margin-bottom: .25rem!important;
	}
	.mb-mrg-top-1 {
		margin-top: .5rem!important;
	}
	.mb-mrg-btm-1 {
		margin-bottom: .5rem!important;
	}
}

@media only screen and (max-width: 668px) {
	.ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
		display: none;
	}
	.ant-picker-panel-container {
		margin: 15px !important;
	}
	.ant-picker-panel-container .ant-picker-panels {
		-webkit-flex-wrap: wrap !important;
		        flex-wrap: wrap !important;
	}
	.ant-picker-panel {
		width: 100%;
	}
	.ant-picker-decade-panel, .ant-picker-year-panel, .ant-picker-quarter-panel, .ant-picker-month-panel, .ant-picker-week-panel, .ant-picker-date-panel, .ant-picker-time-panel {
		width: 100%;
	}
	.ant-picker-date-panel .ant-picker-content {
		width: 100%;
	}
}

@media only screen and (max-width: 768px) {
	.content {
		margin-left: .5rem !important;
	}
	/* Navbar Mobile display css */
	.nav-menu-left {
		margin-left: -15px;
	}
	.custom_bar_icon {
		display: -webkit-flex !important;
		display: flex !important;
		-webkit-align-items: center !important;
		        align-items: center !important;
	}
}

.modal {
	z-index: 1100 !important;
}

/* .dragContainer, .dropContainer{
	display:flex;
	flex-wrap: wrap;
}

.dropContainer.left{
	order: -1;
}
.dragOverlay{
	background: transparent;
	color: transparent;
}
.draggable-item {
    background: #6993ff !important;
    box-shadow: 0px 0px 0px transparent;
    text-shadow: 0px 0px 0px transparent;
	color:#fff;
	z-index: 99999;
  }
  
  .draggable-item:hover {
    background: #6993ff !important;
    box-shadow: 0px 0px 0px transparent;
    text-shadow: 0px 0px 0px transparent;
	color:#fff;
  }
  
  .draggable-item:active {
    outline: none;
    border: none;
  }
  
  .droppable-item:focus {
    outline: 0;
  }
  
  .droppable-item {
    background: gray !important;
    box-shadow: 0px 0px 0px transparent;
    text-shadow: 0px 0px 0px transparent;
	color:#fff;
	position: relative;
  }
  .droppable-item button{
	  position: absolute;
	  top: 0;
  }
  
  .droppable-item:active, .dragOverlay:active {
    outline: none;
    border: none;
  }
  
  .droppable-item:focus, .dragOverlay:focus {
    outline: 0;
  }
  .draggable-item, .droppable-item, .dragOverlay{
	  margin: .5rem;
	  padding:5px;
	  width:95%;
	  border: unset !important;;
  }
  .droppable-item .draggable-item {
	  margin:0!important;
  } */

.answer_explanation img {
	width : unset !important;
}
.custom-sidebar .pro-sidebar{
    position: fixed;
    height: 100%;
    width: 16%;
    min-width: 16%;
    color: #3f4254;
}
.custom-sidebar .pro-sidebar.collapsed {
    width: 80px;
    min-width: 80px;
}
.logo_with_out_collapsed{
    width: 100%;
    max-width: 80px;
    display: block;
    margin: 0 auto;
    margin-top: 5px;
    padding: 15px;
}
.logo_collapsed{
    width: 100%;
    max-width: 40px;
    display: block;
    margin: 0 auto;
    margin-top: 5px;
    padding: 5px;
}

.logo_with_out_collapsed img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.logo_collapsed img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.menu_item_icon_color{
    height: 1.5em;
    width: 1.5em !important;
    color: #1bc5bd !important;
}




.custom-sidebar .pro-sidebar > .pro-sidebar-inner{
    background: #fff;
}
.custom-sidebar .pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper{
    background: unset;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper{
    width: auto;
    line-height: normal;
    line-height: initial;
    height: auto;
    min-width: auto;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover{
    background: #f6f6f6;
    color: #56c5bd;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before{
    width: 8px;
    min-width: 8px;
    height: 8px;
    border: 1px solid #03a9f3;
    background: #03a9f3;
    box-shadow: unset;
    color: #56c5bd !important;
}

/* .pro-inner-item:after{
    color: #56c5bd;
} */
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item{
    font-size: 0.875rem;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item{
    background: #fff;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus{
    color: #56c5bd;
}



.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner{
    background: #fff;
    box-shadow: 0 0 0 0.2rem rgba(197,200,211,.5);
}




@media only screen  and (max-width: 768px){
	.custom-sidebar .pro-sidebar {
        width: 270px;
        min-width: 270px;
    }
}

.pro-sidebar .pro-menu .pro-menu-item.active{
    background: #cdefff;
    color: #003642;  /* put any color you want */
    font-weight: 500;
}
  /* This is for submenu item */
  .nav-member .react-slidedown .pro-menu-item a.active {
    color: #003642;  /* put any color you want */
    font-weight: 500;
  }

.page_header_content{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: stretch;
            align-items: stretch;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    min-height: 70px;
    padding-top: 0;
    padding-bottom: 0;
    background-color: transparent;
}
.page_header_title{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin: .5rem;
    margin-left: 0.5rem;
    margin-left: 0.5rem;
    margin-left: 0;
}
.new-style{
    text-align: center;
}
.page_header_title_label{
    font-weight: 500;
    font-size: 1.275rem;
    color: #181c32;
    line-height: 1.5;
}
.page_header_subtitle{
    line-height: 1.5;
}

.content_header_card_body{
    /* border-bottom: 2px solid #48a2b8 !important; */
    border-radius: 4px;
}

.content_header_card_body:last-child {
   border: unset;
}
.content_header_card_title{
    font-size: 18px !important;
    font-weight: unset !important;
}
.c-card-bg{
    /* background: #f0f2f6 !important; */
    background-color: #fff !important;
    box-shadow: 0 0 30px 0 rgba(82,63,105,.05);
    /* margin: 10px; */
}
.w-31{
    width: 31%;
}
.card_item{
    margin-top: 10px;
}



@media only screen and (max-width:767px){
    .card_item{
        width: 100%;
    }
    .w-31{
        width: 100%;
    }
}
.react-bootstrap-table table {
  table-layout: auto;
}
.my_table_with_action{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
}
.blank-page-area{
    width: 100%;
    max-width: 550px;
    display: block;
    margin: 0 auto;
}
.no-data-icon-color{
    color: #ffc107;
    font-size: 56px;
}

.course_name{
    cursor: pointer;
    background: #f3f6f9 !important;
    padding: 15px;
    /* background: #fff; */
    box-shadow: 0 0 30px 0 rgba(82,63,105,.05);
    border-radius: 10px;
    margin: 10px;
}
.course_name_two{
    /* background: #f3f6f9 !important; */
    padding: 15px;
    background: #fff;
    box-shadow: 0 0 30px 0 rgba(82,63,105,.05);
    border-radius: 10px;
    /* margin: 15px; */
}


.course_icon{
    width: 70px;
    height: 70px;
    line-height: 60px;
    background-color: #56c5bd;
    border-radius: 100%;
    color: #FFF;
    text-align: center;
    display: block;
    margin: 0 auto;
    transition: 0.3s;
    font-size: 45px;
    margin-top: 1rem;
}
.course_name h3, .course_name_two h3{
    font-size: 22px;
    text-align: center;
    margin-top: 10px;
}


@media only screen and (max-width: 768px){
    .mbl-mb-3{
        margin-bottom: 1.5rem;
    }
}
.courses{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.ant-image{
    width: 80% !important;
    margin: 0 auto !important;
}
/* .courses{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
} */
.custom_course_topic_card{
    background-color: #fff;
}
.custom_course_topic_card_head{
    padding: 0 10px;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 1px solid #bfbfbf;
    min-height: 40px;
    line-height: 40px;
}
.custom_course_topic_card_head p{
    margin: 0;
}
.custom_course_topic_card_body{
    padding: 20px 0;
}
.document_image img{
    width: 100%;
    height: 200px;
    max-width: 330px;
}


.video-react .video-react-big-play-button{
    -webkit-transform: translate(-50%, -50%) !important;
            transform: translate(-50%, -50%) !important;
    top: 50% !important;
    left: 50% !important;
}
.video-react .video-react-control{
    width: 3em !important;
    /* overflow: hidden !important; */
}
.custom_course_topic_card{
    background-color: #fff;
}
.custom_course_topic_card_head{
    padding: 0 10px;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 1px solid #bfbfbf;
    min-height: 40px;
    line-height: 40px;
}
.custom_course_topic_card_head p{
    margin: 0;
}
.custom_course_topic_card_body{
    padding: 20px 0;
}
.document_image img{
    width: 100%;
    height: 200px;
    max-width: 330px;
}

.not_found{
    padding-left: 30px;
}


.video-react .video-react-big-play-button{
    -webkit-transform: translate(-50%, -50%) !important;
            transform: translate(-50%, -50%) !important;
    top: 50% !important;
    left: 50% !important;
}
.video-react .video-react-control{
    width: 3em !important;
    /* overflow: hidden !important; */
}
.question-review-mod{
    height: 700px !important;
    overflow-y: scroll !important;
}

.questionReviewModal{
    max-width: 100%;
    margin: 0 auto;
}

@media only screen and (max-width:767px){
    .question-item{
        width: 100% !important;
    }
}
.question_break_footer{
    min-height: 300px;
    -webkit-align-self: stretch;
            align-self: stretch;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
}
.question_break_footer div div{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.question_break_footer div div h4{
    /* display: none; */
}
.question_break_footer div div div span{
    font-size: xxx-large;
}


.question_break_footer .question_break_footer_btn{
    width: 60%;
    display: block;
    margin: 40px auto;
}
.min-height-150{
    min-height: 150px;
}
.pickAnswerImg img{
    width: 100%;
    max-width: 600px;
    border-radius: 5px;
}

.answer_dragable_overlap_parent{
    margin: 20px 8px;
    display:-webkit-flex;
    display:flex;
    -webkit-justify-content: center;
            justify-content: center;
}
.answer_dragable_overlap, .question_fixed{
    padding:1rem 45px!important;
}
.answer_dragable_overlap.right{
    margin-left: -40px;
    margin-bottom: -5px;
    margin-top: 5px;
    z-index: 999;
}
.answer_dragable_overlap.left{
    margin-right: -40px;
    margin-bottom: -5px;
    margin-top: 5px;
    z-index: 999;
    padding:1rem 45px;
}
.exam_break{
    min-height: 300px;
    -webkit-align-self: stretch;
            align-self: stretch;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
}
.exam_break div div{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.exam_break div div h4{
    /* display: none; */
}
.exam_break div div div span{
    font-size: xxx-large;
}


.exam_break .exam_break_btn{
    width: 60%;
    display: block;
    margin: 40px auto;
}
.min-height-150{
    min-height: 150px;
}
.width-12{
    width: 120px;
}


@media only screen and (max-width: 767px){
    .width-12{
        width: 100px;
    }
    .exam-details-review{
        -webkit-justify-content: space-around !important;
                justify-content: space-around !important;
    }
}
.modal-dialog-custom {
    max-width: unset !important;
    margin: 1.75rem auto;
}

.modal-90w {
    width: 90% !important;
}

.drag_drop_right_side {
    left: -25px !important;
}
.mb-15{
    margin-bottom: 2em !important;
}
.mb-20{
    margin-bottom: 3em !important;
}
.bgi-size-cover{
    background-size: cover;
}
.bgi-no-repeat {
    background-repeat: no-repeat;
}
.bgi-position-top {
    background-position: 0 top;
}
.login-area{
    width: 100%;
    max-width: 450px;
    padding: 50px 10px;
    min-height: 600px;
    border-radius: 10px;
}
.login-area-logo img{
    width: 100%;
    max-width: 100px;
    display: block;
    margin: 0 auto;
}
.login-input-form{
    background-color: #f3f6f9;
    border-color: #f3f6f9;
    height: calc(1.5em + 1.3rem + 5px);
    color: #3f4254;
    border-radius: .42rem;
}
.login-input-form.form-control:focus{
    box-shadow: unset !important;
}
.pl-8, .px-8 {
    padding-left: 2rem!important;
}
.pr-8, .px-8 {
    padding-right: 2rem!important;
}
.pl-9, .px-9 {
    padding-left: 2.25rem!important;
}
.pr-9, .px-9 {
    padding-right: 2.25rem!important;
}
.login-content{
    margin-left: -20%;
}


/* login new page css */
.login-container{
    width: 100%;
    margin: 0 auto;
}
.login-100{
    width: 100%;
    min-height: 100vh;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 15px;
    background: #f3f6f9;
}
.wrap-login100 {
    width: 1170px;
    background: #fff;
    overflow: hidden;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: stretch;
            align-items: stretch;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-align-self: stretch;
            align-self: stretch;
}
.login100-form{
    width: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 40px 65px 40px 65px;
    margin-top: auto;
    margin-bottom: auto;
}
.form-100{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}


.login-logo{
    width: 100%;
}
.login-logo img {
    max-width: 200px;
    margin: auto;
    display: block;
    margin-bottom: 56px;
}

/* inputs */
.wrap-input100 {
    width: 100%;
    position: relative;
    border: 1px solid #e6e6e6;
  }
  
  .rs1-wrap-input100,
  .rs2-wrap-input100 {
    width: 50%;
  }
  
  .rs2-wrap-input100 {
    border-left: none;
  }
  
  
  .input100 {
    display: block;
    width: 100%;
    background: transparent;
    font-size: 14px;
    color: #666666;
    line-height: 1.2;
    padding: 0 25px;
  }
  

  input.input100 {
    height: 55px;
  }
  /* focus input */
  .focus-input100 {
    position: absolute;
    display: block;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    top: -1px;
    left: -1px;
    pointer-events: none;
    border: 1px solid #00ad5f !important;
  
    visibility: hidden;
    opacity: 0;
    transition: all 0.4s;
  
    -webkit-transform: scaleX(1.1) scaleY(1.3);
    transform: scaleX(1.1) scaleY(1.3);
  }
  
  .input100:focus + .focus-input100 {
    visibility: visible;
    opacity: 1;
  
    -webkit-transform: scale(1);
    transform: scale(1);
  }


  /* form-control focus  remove*/
  .form-control:focus{
      border-radius: 0 !important;
      color: unset !important;
      background-color: unset !important;
      border-color: unset !important;
      outline: 0 !important;
  }
  

  /* login btn */
  .login100-form-btn {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 50px;
    border-radius: 3px;
    background: #00ad5f;
    border: 1px solid #00ad5f;
    font-size: 12px;
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: all 0.4s;
  }
  .login100-form-btn:hover{
    background: #00ad5f;
    border: 1px solid #00ad5f;
  }
  .login100-form-btn:focus{
    background: #00ad5f;
    border: 1px solid #00ad5f;
  }



  /* login left bg */
  .login100-more{
    width: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
}
  .login100-more::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.3);
}
.div-footer{
    position: absolute;
    bottom: 50px;
    color: #404040;
    padding-left: 40px;
}
.div-footer h4 {
    padding-bottom: 5px;
    border-bottom: 1px solid #0f32367d;
    margin-bottom: 5px;
    font-size: 12px;
    margin-left: 6px;
    color: #575958;
}
.div-footer h5{
    padding: 5px;
    font-size: 10px;
    color: #575958;
}
.mrg-top-min-200{
    margin-top: -200px;
}

@media only screen and (max-width:992px) {
    .login100-form {
      width: 60%;
      padding-left: 30px;
      padding-right: 30px;
    }
  
    .login100-more {
      width: 40%;
    }
    
    .footer-div {
      position: absolute !important;
      bottom: 345px !important;
      color: #0f3236 !important;
      padding-left: 25px !important;
    }
  
  }
  
  @media only screen and (max-width:768px) {

    .login-content{
        margin-left: 0;
    }
    .login100-form {
      width: 100%;
    }
  
    .login100-more {
      width: 100%;
    }
    .div-footer{
      display: none;
    }
    .login100-more{
        display: none;
    }
  }
  
  @media only screen and (max-width:576px) {
    .login100-form {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 70px;
    }
  
    .rs1-wrap-input100,
    .rs2-wrap-input100 {
      width: 100%;
    }
  
    .rs2-wrap-input100 {
      border-left: 1px solid #e6e6e6;
    }
    .mrg-top-min-200{
      margin-top: 0 !important;
    }
  }

  i {
    color: #7E7E7E;
    position: absolute;
    top: 30%;
    right: 6%;
  }
  i:hover {
    color: #7E7E7E;
    cursor: pointer;
  }




.accordion-wrapper+* {
    margin-top: 0.5em;
}

.accordion-item {
    overflow: hidden;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;
}

.accordion-item.collapsed {
    max-height: 0;
    transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.accordion-title {
    font-weight: 600;
    cursor: pointer;
    color: #666;
    padding: 0.5em 1.5em;
    border: solid 1px #ccc;
    border-radius: 1.5em;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.accordion-title::after {
    content: "";
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid currentColor;
}

.accordion-title:hover, .accordion-title.open {
    color: black;
}

.accordion-title.open::after {
    content: "";
    border-top: 0;
    border-bottom: 5px solid;
}

.accordion-content {
    padding: 1em 1.5em;
}
.wrapper {
    width: 600px;
    margin: 0 auto;
  }
.change_password_form{
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}


.input_group_text_icon{
    background-color: #f3f6f9;
    border-color: #f3f6f9;
    color: #3f4254;
}
