.modal-dialog-custom {
    max-width: unset !important;
    margin: 1.75rem auto;
}

.modal-90w {
    width: 90% !important;
}

.drag_drop_right_side {
    left: -25px !important;
}