
.page_header_content{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    min-height: 70px;
    padding-top: 0;
    padding-bottom: 0;
    background-color: transparent;
}
.page_header_title{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: .5rem;
    margin-left: 0.5rem;
    margin-left: 0.5rem;
    margin-left: 0;
}
.new-style{
    text-align: center;
}
.page_header_title_label{
    font-weight: 500;
    font-size: 1.275rem;
    color: #181c32;
    line-height: 1.5;
}
.page_header_subtitle{
    line-height: 1.5;
}