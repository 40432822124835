.mb-15{
    margin-bottom: 2em !important;
}
.mb-20{
    margin-bottom: 3em !important;
}
.bgi-size-cover{
    background-size: cover;
}
.bgi-no-repeat {
    background-repeat: no-repeat;
}
.bgi-position-top {
    background-position: 0 top;
}
.login-area{
    width: 100%;
    max-width: 450px;
    padding: 50px 10px;
    min-height: 600px;
    border-radius: 10px;
}
.login-area-logo img{
    width: 100%;
    max-width: 100px;
    display: block;
    margin: 0 auto;
}
.login-input-form{
    background-color: #f3f6f9;
    border-color: #f3f6f9;
    height: calc(1.5em + 1.3rem + 5px);
    color: #3f4254;
    border-radius: .42rem;
}
.login-input-form.form-control:focus{
    box-shadow: unset !important;
}
.pl-8, .px-8 {
    padding-left: 2rem!important;
}
.pr-8, .px-8 {
    padding-right: 2rem!important;
}
.pl-9, .px-9 {
    padding-left: 2.25rem!important;
}
.pr-9, .px-9 {
    padding-right: 2.25rem!important;
}
.login-content{
    margin-left: -20%;
}


/* login new page css */
.login-container{
    width: 100%;
    margin: 0 auto;
}
.login-100{
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background: #f3f6f9;
}
.wrap-login100 {
    width: 1170px;
    background: #fff;
    overflow: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    flex-direction: row-reverse;
    align-self: stretch;
}
.login100-form{
    width: 50%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    padding: 40px 65px 40px 65px;
    margin-top: auto;
    margin-bottom: auto;
}
.form-100{
    display: flex;
    flex-wrap: wrap;
}


.login-logo{
    width: 100%;
}
.login-logo img {
    max-width: 200px;
    margin: auto;
    display: block;
    margin-bottom: 56px;
}

/* inputs */
.wrap-input100 {
    width: 100%;
    position: relative;
    border: 1px solid #e6e6e6;
  }
  
  .rs1-wrap-input100,
  .rs2-wrap-input100 {
    width: 50%;
  }
  
  .rs2-wrap-input100 {
    border-left: none;
  }
  
  
  .input100 {
    display: block;
    width: 100%;
    background: transparent;
    font-size: 14px;
    color: #666666;
    line-height: 1.2;
    padding: 0 25px;
  }
  

  input.input100 {
    height: 55px;
  }
  /* focus input */
  .focus-input100 {
    position: absolute;
    display: block;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    top: -1px;
    left: -1px;
    pointer-events: none;
    border: 1px solid #00ad5f !important;
  
    visibility: hidden;
    opacity: 0;
  
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  
    -webkit-transform: scaleX(1.1) scaleY(1.3);
    -moz-transform: scaleX(1.1) scaleY(1.3);
    -ms-transform: scaleX(1.1) scaleY(1.3);
    -o-transform: scaleX(1.1) scaleY(1.3);
    transform: scaleX(1.1) scaleY(1.3);
  }
  
  .input100:focus + .focus-input100 {
    visibility: visible;
    opacity: 1;
  
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }


  /* form-control focus  remove*/
  .form-control:focus{
      border-radius: 0 !important;
      color: unset !important;
      background-color: unset !important;
      border-color: unset !important;
      outline: 0 !important;
  }
  

  /* login btn */
  .login100-form-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 50px;
    border-radius: 3px;
    background: #00ad5f;
    border: 1px solid #00ad5f;
    font-size: 12px;
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: 1px;
  
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  }
  .login100-form-btn:hover{
    background: #00ad5f;
    border: 1px solid #00ad5f;
  }
  .login100-form-btn:focus{
    background: #00ad5f;
    border: 1px solid #00ad5f;
  }



  /* login left bg */
  .login100-more{
    width: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
}
  .login100-more::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.3);
}
.div-footer{
    position: absolute;
    bottom: 50px;
    color: #404040;
    padding-left: 40px;
}
.div-footer h4 {
    padding-bottom: 5px;
    border-bottom: 1px solid #0f32367d;
    margin-bottom: 5px;
    font-size: 12px;
    margin-left: 6px;
    color: #575958;
}
.div-footer h5{
    padding: 5px;
    font-size: 10px;
    color: #575958;
}
.mrg-top-min-200{
    margin-top: -200px;
}

@media only screen and (max-width:992px) {
    .login100-form {
      width: 60%;
      padding-left: 30px;
      padding-right: 30px;
    }
  
    .login100-more {
      width: 40%;
    }
    
    .footer-div {
      position: absolute !important;
      bottom: 345px !important;
      color: #0f3236 !important;
      padding-left: 25px !important;
    }
  
  }
  
  @media only screen and (max-width:768px) {

    .login-content{
        margin-left: 0;
    }
    .login100-form {
      width: 100%;
    }
  
    .login100-more {
      width: 100%;
    }
    .div-footer{
      display: none;
    }
    .login100-more{
        display: none;
    }
  }
  
  @media only screen and (max-width:576px) {
    .login100-form {
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 70px;
    }
  
    .rs1-wrap-input100,
    .rs2-wrap-input100 {
      width: 100%;
    }
  
    .rs2-wrap-input100 {
      border-left: 1px solid #e6e6e6;
    }
    .mrg-top-min-200{
      margin-top: 0 !important;
    }
  }

  i {
    color: #7E7E7E;
    position: absolute;
    top: 30%;
    right: 6%;
  }
  i:hover {
    color: #7E7E7E;
    cursor: pointer;
  }



