.custom-sidebar .pro-sidebar{
    position: fixed;
    height: 100%;
    width: 16%;
    min-width: 16%;
    color: #3f4254;
}
.custom-sidebar .pro-sidebar.collapsed {
    width: 80px;
    min-width: 80px;
}
.logo_with_out_collapsed{
    width: 100%;
    max-width: 80px;
    display: block;
    margin: 0 auto;
    margin-top: 5px;
    padding: 15px;
}
.logo_collapsed{
    width: 100%;
    max-width: 40px;
    display: block;
    margin: 0 auto;
    margin-top: 5px;
    padding: 5px;
}

.logo_with_out_collapsed img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.logo_collapsed img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.menu_item_icon_color{
    height: 1.5em;
    width: 1.5em !important;
    color: #1bc5bd !important;
}




.custom-sidebar .pro-sidebar > .pro-sidebar-inner{
    background: #fff;
}
.custom-sidebar .pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper{
    background: unset;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-icon-wrapper{
    width: auto;
    line-height: initial;
    height: auto;
    min-width: auto;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover{
    background: #f6f6f6;
    color: #56c5bd;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before{
    width: 8px;
    min-width: 8px;
    height: 8px;
    border: 1px solid #03a9f3;
    background: #03a9f3;
    box-shadow: unset;
    color: #56c5bd !important;
}

/* .pro-inner-item:after{
    color: #56c5bd;
} */
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item{
    font-size: 0.875rem;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item{
    background: #fff;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus{
    color: #56c5bd;
}



.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner{
    background: #fff;
    box-shadow: 0 0 0 0.2rem rgba(197,200,211,.5);
}




@media only screen  and (max-width: 768px){
	.custom-sidebar .pro-sidebar {
        width: 270px;
        min-width: 270px;
    }
}

.pro-sidebar .pro-menu .pro-menu-item.active{
    background: #cdefff;
    color: #003642;  /* put any color you want */
    font-weight: 500;
}
  /* This is for submenu item */
  .nav-member .react-slidedown .pro-menu-item a.active {
    color: #003642;  /* put any color you want */
    font-weight: 500;
  }