.exam_break{
    min-height: 300px;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.exam_break div div{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.exam_break div div h4{
    /* display: none; */
}
.exam_break div div div span{
    font-size: xxx-large;
}


.exam_break .exam_break_btn{
    width: 60%;
    display: block;
    margin: 40px auto;
}
.min-height-150{
    min-height: 150px;
}