.width-12{
    width: 120px;
}


@media only screen and (max-width: 767px){
    .width-12{
        width: 100px;
    }
    .exam-details-review{
        justify-content: space-around !important;
    }
}