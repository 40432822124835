.question-review-mod{
    height: 700px !important;
    overflow-y: scroll !important;
}

.questionReviewModal{
    max-width: 100%;
    margin: 0 auto;
}

@media only screen and (max-width:767px){
    .question-item{
        width: 100% !important;
    }
}