.custom_course_topic_card{
    background-color: #fff;
}
.custom_course_topic_card_head{
    padding: 0 10px;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 1px solid #bfbfbf;
    min-height: 40px;
    line-height: 40px;
}
.custom_course_topic_card_head p{
    margin: 0;
}
.custom_course_topic_card_body{
    padding: 20px 0;
}
.document_image img{
    width: 100%;
    height: 200px;
    max-width: 330px;
}

.not_found{
    padding-left: 30px;
}


.video-react .video-react-big-play-button{
    transform: translate(-50%, -50%) !important;
    top: 50% !important;
    left: 50% !important;
}
.video-react .video-react-control{
    width: 3em !important;
    /* overflow: hidden !important; */
}