.pickAnswerImg img{
    width: 100%;
    max-width: 600px;
    border-radius: 5px;
}

.answer_dragable_overlap_parent{
    margin: 20px 8px;
    display:flex;
    justify-content: center;
}
.answer_dragable_overlap, .question_fixed{
    padding:1rem 45px!important;
}
.answer_dragable_overlap.right{
    margin-left: -40px;
    margin-bottom: -5px;
    margin-top: 5px;
    z-index: 999;
}
.answer_dragable_overlap.left{
    margin-right: -40px;
    margin-bottom: -5px;
    margin-top: 5px;
    z-index: 999;
    padding:1rem 45px;
}