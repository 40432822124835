@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap');
body {
	background: #f3f6f9 !important;
	font-family: 'Roboto', sans-serif !important;
	/* font-family: 'Lato', sans-serif; */
}

.table thead th:focus {
	outline: unset !important;
}

a, a:hover, a:focus {
	color: inherit;
	text-decoration: none;
	transition: all 0.2s;
}

.fs-6 {
	font-size: 14px !important;
}

.fs-7 {
	font-size: 1.3rem !important;
}

.fs-24 {
	font-size: 24px !important;
}

.cp {
	cursor: pointer;
}

.mark-color {
	color: #f64e60 !important;
}

.check-color {
	color: #1bc5bd !important;
}

.ban-color {
	color: #f0a1a9 !important;
}

.deep-ban-color {
	color: #ff0019 !important;
}

.c-btn-primary {
	color: #fff !important;
	background-color: #1bc5bd !important;
	border-color: #1bc5bd !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}

.c-btn-danger {
	color: #fff !important;
	background-color: #f64e60 !important;
	border-color: #f64e60 !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}

.c-btn-info {
	color: #fff !important;
	background-color: #6993ff !important;
	border-color: #6993ff !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}

.c-btn-cyan {
	color: #1bc5bd !important;
	background-color: #f3f6f9 !important;
	border-color: #f3f6f9 !important;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}

.c-btn-secondary {
	color: #3f4254 !important;
	background-color: #e4e6ef !important;
	border-color: #e4e6ef !important;
}

.pb-10, .py-10 {
	padding-bottom: 5rem !important;
}

.pt-10, .py-10 {
	padding-top: 5rem!important;
}

.pad-top-2 {
	padding-top: 2.5rem !important;
}

/* Alert Css */

#__react-alert__ div div div {
	background-color: #1bc5bd !important;
	border-color: #1bc5bd !important;
	color: #fff !important;
	margin: 15px 30px !important;
}

#__react-alert__ div div div svg {
	stroke: #fff;
}

#__react-alert__ div div div span {
	text-transform: capitalize;
}

.App {
	display: flex;
	width: 100%;
	align-items: stretch;
	/* position: fixed; */
	height: 100vh;
	z-index: 1;
}

.content_bg {
	-webkit-box-shadow: 0 0 30px 0 rgba(82, 63, 105, .05);
	box-shadow: 0 0 30px 0 rgba(82, 63, 105, .05);
	border: 0;
	padding: 5px;
	background: #fff;
}

.content-wrap-pad {
	padding: 0 1.25rem 0 1.25rem;
}

.font-size-sm {
	font-size: .925rem !important;
}

.in-progress {
	color: #17a2b8!important
}

.success {
	color: #28a745!important
}

.warning {
	color: #ffc107!important;
}

.border-btm {
	border-bottom: 2px solid #17a2b8;
}

.content {
	margin-left: 17% !important;
	transition: .5s;
}

.content-collpased {
	margin-left: 7% !important;
	transition: .5s;
}

.sidenav-btn {}

.sidenav-btn-possition {
	position: fixed;
	right: 0;
	bottom: 40px;
	height: 36px;
	width: 36px;
	z-index: 1098;
	left: 14%;
	transition: .5s !important;
}

.sidenav-btn-possition-collapsed {
	position: fixed;
	right: 0;
	bottom: 40px;
	height: 36px;
	width: 36px;
	z-index: 1098;
	left: 4%;
	transition: .5s !important;
}

/* .content.collapsed{
	margin-left: 6.5% !important;
	transition: .5s;
} */

.text-hover-primary:hover {
	transition: color .15s ease, background-color .15s ease, border-color .15s ease, box-shadow .15s ease, -webkit-box-shadow .15s ease;
	color: #1bc5bd !important;
}

.text-justified {
	text-align: justify;
}

/* pagination css */

.pagination-overfolow {
	width: 100%;
	display: block;
	overflow-x: auto;
}

/* Button CSS */

.custom-filter-btn {
	height: auto;
	padding: 7px 15px;
}

/* Margin CSS */

.mrg-top-64 {
	margin-top: 64px !important;
}

/* Exam Form Css */

.exam_heading_with_action {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: center;
}

/* Nav CSS */

.custom_nav {
	/* background: linear-gradient( 168deg, #13b4ca, #18cabe); */
	/* height: 50px; */
	margin-left: 21%;
}

.custom_bar_icon, .custom_bar_icon:focus, .custom_bar_icon.btn-primary.focus, .custom_bar_icon.btn-primary:focus {
	border: 0 !important;
	background: inherit !important;
	padding: 0.25rem 0.75rem !important;
	font-size: 24px !important;
	line-height: 1 !important;
	box-shadow: unset !important;
	color: #181c32!important;
}

.nav_text {
	color: #181c32!important;
	font-size: 15px;
}

.transition-1 {
	transition: .5s !important;
}

/* Question Css */

.question_img_area img {
	width: 100%;
	max-width: 600px;
	border-radius: 5px;
}



/* DragDrop CSS */
.details-modal-question-bg{
	border: 1px solid #f1c0c5  !important;
    background: #f1c0c5  !important;
}
.draggable-item{
	border: 1px solid #f1c0c5  !important;
    background: #f1c0c5  !important;
}
.droppable-item{
	cursor: unset !important;
}
.draggable-item, .droppable-item{
	padding: 15px 40px;
}

.draggable-item.dragged-left{
width: 100%;
margin-left: -40px;
margin-bottom: -5px;
margin-top: 5px;
z-index: 999;
}
.draggable-item.dragged-right{
width: 100%;
margin-left: 40px;
margin-bottom: -5px;
margin-top: 5px;
z-index: 999;
}
.bg-gray {
	background-color: #d3d3d3 !important;
}

.bg-light-green {
	background-color: #dffcdd !important;
}






@media only screen and (max-width: 1270px) {
	.draggable-item, .droppable-item{
		padding: 5px;
	}
}


@media only screen and (max-width: 445px) {
	/* mobile margin bottom css */
	.mb-mrg-top-2 {
		margin-top: 1.25rem;
	}
	.mb-mrg-btm-2 {
		margin-bottom: 1.25rem;
	}
	.mb-mrg-top {
		margin-top: .25rem!important;
	}
	.mb-mrg-btm {
		margin-bottom: .25rem!important;
	}
	.mb-mrg-top-1 {
		margin-top: .5rem!important;
	}
	.mb-mrg-btm-1 {
		margin-bottom: .5rem!important;
	}
}

@media only screen and (max-width: 668px) {
	.ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
		display: none;
	}
	.ant-picker-panel-container {
		margin: 15px !important;
	}
	.ant-picker-panel-container .ant-picker-panels {
		flex-wrap: wrap !important;
	}
	.ant-picker-panel {
		width: 100%;
	}
	.ant-picker-decade-panel, .ant-picker-year-panel, .ant-picker-quarter-panel, .ant-picker-month-panel, .ant-picker-week-panel, .ant-picker-date-panel, .ant-picker-time-panel {
		width: 100%;
	}
	.ant-picker-date-panel .ant-picker-content {
		width: 100%;
	}
}

@media only screen and (max-width: 768px) {
	.content {
		margin-left: .5rem !important;
	}
	/* Navbar Mobile display css */
	.nav-menu-left {
		margin-left: -15px;
	}
	.custom_bar_icon {
		display: flex !important;
		align-items: center !important;
	}
}

.modal {
	z-index: 1100 !important;
}

/* .dragContainer, .dropContainer{
	display:flex;
	flex-wrap: wrap;
}

.dropContainer.left{
	order: -1;
}
.dragOverlay{
	background: transparent;
	color: transparent;
}
.draggable-item {
    background: #6993ff !important;
    box-shadow: 0px 0px 0px transparent;
    text-shadow: 0px 0px 0px transparent;
	color:#fff;
	z-index: 99999;
  }
  
  .draggable-item:hover {
    background: #6993ff !important;
    box-shadow: 0px 0px 0px transparent;
    text-shadow: 0px 0px 0px transparent;
	color:#fff;
  }
  
  .draggable-item:active {
    outline: none;
    border: none;
  }
  
  .droppable-item:focus {
    outline: 0;
  }
  
  .droppable-item {
    background: gray !important;
    box-shadow: 0px 0px 0px transparent;
    text-shadow: 0px 0px 0px transparent;
	color:#fff;
	position: relative;
  }
  .droppable-item button{
	  position: absolute;
	  top: 0;
  }
  
  .droppable-item:active, .dragOverlay:active {
    outline: none;
    border: none;
  }
  
  .droppable-item:focus, .dragOverlay:focus {
    outline: 0;
  }
  .draggable-item, .droppable-item, .dragOverlay{
	  margin: .5rem;
	  padding:5px;
	  width:95%;
	  border: unset !important;;
  }
  .droppable-item .draggable-item {
	  margin:0!important;
  } */

.answer_explanation img {
	width : unset !important;
}