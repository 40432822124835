
.course_name{
    cursor: pointer;
    background: #f3f6f9 !important;
    padding: 15px;
    /* background: #fff; */
    box-shadow: 0 0 30px 0 rgba(82,63,105,.05);
    border-radius: 10px;
    margin: 10px;
}
.course_name_two{
    /* background: #f3f6f9 !important; */
    padding: 15px;
    background: #fff;
    box-shadow: 0 0 30px 0 rgba(82,63,105,.05);
    border-radius: 10px;
    /* margin: 15px; */
}


.course_icon{
    width: 70px;
    height: 70px;
    line-height: 60px;
    background-color: #56c5bd;
    border-radius: 100%;
    color: #FFF;
    text-align: center;
    display: block;
    margin: 0 auto;
    transition: 0.3s;
    font-size: 45px;
    margin-top: 1rem;
}
.course_name h3, .course_name_two h3{
    font-size: 22px;
    text-align: center;
    margin-top: 10px;
}


@media only screen and (max-width: 768px){
    .mbl-mb-3{
        margin-bottom: 1.5rem;
    }
}