
.content_header_card_body{
    /* border-bottom: 2px solid #48a2b8 !important; */
    border-radius: 4px;
}

.content_header_card_body:last-child {
   border: unset;
}
.content_header_card_title{
    font-size: 18px !important;
    font-weight: unset !important;
}
.c-card-bg{
    /* background: #f0f2f6 !important; */
    background-color: #fff !important;
    box-shadow: 0 0 30px 0 rgba(82,63,105,.05);
    /* margin: 10px; */
}
.w-31{
    width: 31%;
}
.card_item{
    margin-top: 10px;
}



@media only screen and (max-width:767px){
    .card_item{
        width: 100%;
    }
    .w-31{
        width: 100%;
    }
}