.change_password_form{
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}


.input_group_text_icon{
    background-color: #f3f6f9;
    border-color: #f3f6f9;
    color: #3f4254;
}